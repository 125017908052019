<template>
  <v-container class="pa-0" fluid>
    <!-- loader -->
    <div v-if="loading" class="d-flex flex-column text-center py-12">
      <span class="subtext--text">Obtendo Fotos...</span>

      <loader-content />
    </div>

    <v-card v-else-if="pictures.length > 0" color="altback" class="pa-4 rounded-lg" flat>
      <v-container>
        <!-- header -->
        <v-list-item class="px-0 mb-8">
          <v-list-item-icon class="px-0 mr-4">
            <v-btn class="rounded-lg" icon @click="close()">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ album.name }}
            </v-list-item-title>

            <v-list-item-subtitle class="text-body-1">
              {{ album.description }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action-text class="mr-4 text-right">
            <v-list-item-subtitle>
              <span> {{ formatDate(album.createdAt) }} </span>
            </v-list-item-subtitle>

            <v-list-item-subtitle>
              <span> {{ pictures.length }} Fotos </span>
            </v-list-item-subtitle>
          </v-list-item-action-text>
        </v-list-item>

        <!-- grid -->
        <MansoryGrid
          class="mb-12"
          :data="pictures"
          :loading="loading"
          @refresh="getData()"
        />

        <v-row class="justify-center" no-gutters>
          <v-btn
            :disabled="pagination.page >= pagination.pageCount || loading"
            class="white--text"
            color="gradient"
            depressed
            @click="moreResults()"
          >
            Carregar mais
          </v-btn>
        </v-row>
      </v-container>
    </v-card>

    <!-- empty -->
    <v-row v-else class="subtext--text justify-center py-4" no-gutters>
      <span>Nenhum resultado encontrado</span>
    </v-row>
  </v-container>
</template>

<script>
import { getPictures } from "@/services/gallery";
import { formatDate } from "@/utils";
import MansoryGrid from "@/components/gallery/MansoryGrid";

export default {
  data() {
    return {
      loading: true,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 30,
      },
      pictures: [],
      album: {},
    };
  },

  components: {
    MansoryGrid,
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    // receive pictures
    async getData(concatData = false) {
      try {
        const payload = {
          album_id: this.$route.params.id,
          page: String(this.pagination.page - 1),
          size: this.pagination.itemsPerPage,
        };

        await getPictures(payload).then((res) => {
          const data = res.body.content;
          this.album = res.body.album;

          if (concatData) this.pictures = this.pictures.concat(data);
          else this.pictures = data;

          this.pagination.pageCount = res.body.totalPages;
        });
      } catch (err) {
        console.log(err.data);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },

    moreResults() {
      this.pagination.page++;
      this.getData(true);
    },

    close() {
      this.$router.push({
        path: "/gallery/albums",
      });
    },

    formatDate,
  },
};
</script>
